import DataService from "./dataService";
import config from "../common/config";
import { getPagingLimit } from "../common/storage";
import { getByPageOrder } from "../common/functions";

class ApiStadium {
    getPaging(paging, orders) {
        const limit = getPagingLimit();
        return DataService.get(`/stadium?${ getByPageOrder(paging, orders, limit ? limit : config.PER_PAGE) }`);
    }
    get(id) {
        return DataService.get(`/stadium_detail?id=${id}`);
    }
    addOrUpdate(data) {
        return DataService.post("/stadium", data);
    }
    delete(id) {
        return DataService.delete(`/stadium?id=${id}`);
    }
    getOption(filter = null) {
        var result = 'v=1';
        if (filter && filter.province) result += `&province_id=${filter.province}`;
        if (filter && filter.district) result += `&district_id=${filter.district}`;
        if (filter && filter.ward) result += `&ward_id=${filter.ward}`;
        return DataService.get(`/stadium_option?${result}`);
    }
}

export default new ApiStadium();