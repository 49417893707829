<template>
  <div class="wrapper admin-page">

    <Header />

    <Sidebar />

    <div class="content-wrapper">

      <Breadcrumb />

      <Content />

    </div>

    <Footer />

  </div>
</template>

<script>
import ApiAddress from "../services/apiAddress";
import ApiStadium from "../services/apiStadium";
import { saveProvinces, saveDistricts, saveStadiums } from "../common/storage";
export default {
  components: {
    Sidebar: () => import("./components/Sidebar.vue"),
    Header: () => import("./components/Header.vue"),
    Breadcrumb: () => import("./components/Breadcrumb.vue"),
    Content: () => import("./components/Content.vue"),
    Footer: () => import("./components/Footer.vue")
  },
  beforeCreate() {
    let tag = document.createElement("script");
    tag.setAttribute("src", "/js/jquery.min.js");
    document.head.appendChild(tag);
  },
  methods: {
    async getProvinces() {
      const provinces = await ApiAddress.getProvinces();
      if (provinces && provinces.length > 0)
        saveProvinces(provinces);
    },
    async getDistricts() {
      const districts = await ApiAddress.getDistricts();
      if (districts && districts.length > 0)
        saveDistricts(districts);
    },
    async getStadiums() {
      const stadiums = await ApiStadium.getOption();
      if (stadiums && stadiums.length > 0)
        saveStadiums(stadiums);
    },
  },
  mounted() {
    setTimeout(() => {
      ["/js/bootstrap.bundle.min.js", "/js/adminlte.js"].forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    }, 100);
    this.getProvinces();
    this.getDistricts();
    this.getStadiums();
  }
};
</script>