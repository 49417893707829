import DataService from "./dataService";

class ApiAddress {
    getProvinces() {
        return DataService.get(`/provinces`);
    }
    getDistricts(provinceId = null) {
        var result = "v=1";
        if (provinceId) result += `&province_id=${provinceId}`;
        return DataService.get(`/districts?${result}`);
    }
    getWards(districtId = null) {
        var result = "v=1";
        if (districtId) result += `&district_id=${districtId}`;
        return DataService.get(`/wards?${result}`);
    }
}
export default new ApiAddress();